<template>
	<van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
		<div class="main-wrap">
			<div class="card-list">
				<listItem1 v-for="(item, index) in tableData" :key="index" :itemData="item" :config="listItemConfig">
					<template slot="titleRight">
						<van-rate readonly v-model="item.rate" :size="14"  color="#ffd21e"
                      void-icon="star"
                      void-color="#eee" />
					</template>
					<div class="row-footer" slot="footer">
						<span class="status-item" :style="{ color: formatStatus(item.status).color }">{{ formatStatus(item.status).label }}</span>
						<button type="button" class="button-plan button-plan-primary" @click="cancelShare(item)">取消共享</button>
					</div>
				</listItem1>
			</div>
			<KcEmpty v-if="!tableData.length"></KcEmpty>
		</div>
	</van-pull-refresh>
</template>

<script>
import listItem1 from '@components/listItem/listItem1';
import { getDate } from '@utils/utils.js';
export default {
	name: 'shareSetting',
	components: { listItem1 },
	data() {
		return {
			isRefresh: false,

			listItemConfig: {
				titleKey: 'deviceName',
				layout1: [
					{
						label: '共享次数',
						key: 'loanCount'
					},
					{
						label: '首次共享',
						key: 'firstShareTimeText'
					}
				],
				layout2: [
					{
						label: this.$VUEAPPDEVICECODE(),
						key: 'code'
					},
					{
						label: '卡片号',
						key: 'label'
					},
					{
						label: '设备分类',
						key: 'categoryName'
					},
					{
						label: '品牌',
						key: 'brandName'
					},
					{
						label: '型号',
						key: 'modelName'
					}
				]
			},
			totalCount: 0,
			tableData: [],

			statusOptions: [
				{
					value: 0,
					label: '空闲',
					color: '#67c23a'
				},
				{
					value: 1,
					label: '预约',
					color: '#5fb0ed'
				},
				{
					value: 2,
					label: '借用',
					color: '#27aeb8'
				},
				{
					value: 3,
					label: '消毒',
					color: '#e68b35'
				},
				{
					value: 4,
					label: '报障',
					color: '#e44545'
				}
			]
		};
	},
	created() {
		this.getList();
	},
	mounted() {},
	methods: {
		onRefresh() {
			this.isRefresh = true;
			this.getList(() => {
				setTimeout(() => {
					this.isRefresh = false;
				}, 300);
			});
		},
		getList(callbak) {
			let userInfo = this.$store.getters.userInfo;
			this.$api.deviceWorkManager
				.shareListForWeb({
					query: '',
					page: 1,
					pageSize: 100,
					orderStatus: '',
					tenantIds: userInfo.tenantId,
					isTenantIdChild: 1,
					startTime: getDate(new Date().getTime() - 6 * 8.64e7) + ' 00:00:00',
					endTime: getDate() + ' 23:59:59',
					timeType: 1
				})
				.then(res => {
					res.data.map(item => {
						item.firstShareTimeText = this.$filters.formatDateTime(item.firstShareTime);
					});
					this.tableData = res.data;
					if(callbak) callbak();
				})
				.catch(e => {
					if(callbak) callbak();
				});
		},
		formatStatus(value) {
			const result = this.statusOptions.filter(item => {
				return item.value == value;
			});
			return result.length ? result[0] : {};
		},
		cancelShare(item) {
			this.$dialog
				.confirm({
					message: '是否取消共享该设备？'
				})
				.then(() => {
					this.$api.deviceWorkManager
						.deviceAddOrMoveShare({
							deviceId: item.deviceId,
							share: 0
						})
						.then(res => {
							this.$toast.success('已取消');
							this.getList();
              localStorage.setItem('allDeviceUpdate', new Date().getTime())
            })
						.catch(e => {
							this.$toast.fail('操作失败');
						});
				})
				.catch(() => {});
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
@import '@styles/layout.scss';
.row-footer {
	justify-content: space-between !important;
	.status-item {
		font-size: 16px;
		color: #00e78c;
	}
}
</style>
